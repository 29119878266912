import { configureStore } from "@reduxjs/toolkit";
import nftReducer from "./reducer/nftReducer";
import authReducer from "./reducer/authReducer";
import transactionReducer from "./reducer/transactionReducer";
import depositReducer from "./reducer/depositReducer";
import filtersReducer from './reducer/filterReducer';
const reducer = {
  nft: nftReducer,
  auth: authReducer,
  deposit: depositReducer,
  transaction: transactionReducer,
  filters: filtersReducer,
};

const store = configureStore({
  reducer,
});

export default store;
