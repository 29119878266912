import React from 'react';
import AdminLayout from 'layout/layout';
import { Route, Switch } from 'react-router-dom';
import List from 'pages/nft-management/List';
import SignNFT from 'pages/sign-nft';

const AdminPage = () => {
  return (
    <AdminLayout>
      <Switch>
        <Route path="/" exact render={() => <List />} />
        <Route path="/nft-management" exact render={() => <List />} />
        <Route path="/sign-nft" exact render={() => <SignNFT />} />
        {/* <Route
          path="/database-management"
          exact
          render={() => <ManagementDatabase />}
        />
        <Route path='/deposit-mgem' exact render={() => <DepositMgem />} />
        <Route path='/withdraw-mgem' exact render={() => <WithdrawMgem />} />
        <Route
          exact
          path="/withdraw-details/:withdrawID"
          render={() => <WithdrawDetails />}
        /> */}
        {/* <Route
          path="/resource-in-game"
          exact
          render={() => <ResourceInGame />}
        /> */}
        {/* <Route
          path="/resource-out-game"
          exact
          render={() => <ResourceOutGame />}
        /> */}
        {/* <Route path="/user-in-game" exact render={() => <UserInGame />} /> */}
        {/* <Route path="/ledger" exact render={() => <Ledger />} /> */}
        {/* <Route path="/withdraw-detail" exact render={() => <WithDrawDetail />} /> */}
      </Switch>
    </AdminLayout>
  );
};

export default AdminPage;
