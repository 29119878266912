import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { getNFTsApi, getResourceHistoriesApi, getSignInfoApi, postSignApi, removeFromSaleNotOnBlockchainApi } from "../../api/nfts";
import { instanceOfDataError } from "../../utils/apiError";

export const getNFTs = createAsyncThunk(
  "NFTs/getNFTs",
  async (
    { gameItemType, status, page, size, orders },
    { rejectWithValue }
  ) => {
    const response = await getNFTsApi({
      gameItemType, status, page, size, orders
    });

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);
export const getSignInfo = createAsyncThunk(
  "NFTs/getSignInfo",
  async (id) => {
    const response = await getSignInfoApi(id);
    return response;
  }
);
export const postSign = createAsyncThunk(
  "NFTs/postSign",
  async (
    { nftId,
      price,
      buyNowPrice,
      type,
      royalty,
      signature },
    { rejectWithValue }
  ) => {
    const response = await postSignApi({
      nftId,
      price,
      buyNowPrice,
      type,
      royalty,
      signature
    });
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getSign = createAsyncThunk(
  "NFTs/postSign",
  async (
    { gameItemType,
      status,
      page,
      size,
      orders },
    { rejectWithValue }
  ) => {
    const response = await postSignApi({
      gameItemType,
      status,
      page,
      size,
      orders
    });
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const removeFromSaleNotOnBlockchain = createAsyncThunk('NFT/removeFromSaleNotOnBlockchain',
  async ({ tokenId }, { rejectWithValue }) => {
    const response = await removeFromSaleNotOnBlockchainApi({ tokenId });
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return { response, tokenId };
  })

export const resourceHistories = createAsyncThunk('NFT/getResourceHistories',
  async ({ type, actionType, page, size, order }) => {
    const response = await getResourceHistoriesApi({ type, actionType, page, size, order })
    if (instanceOfDataError(response)) {
      return isRejectedWithValue(response.error)
    }
    return response
  })