import { getApi, postApi } from "../utils/apiHelper";
import { API_URLS } from "./api_url";

export const getPendingDeposit = async () => {
  const data = await getApi(API_URLS.getPendingDeposit);
  return data;
};

export const getPendingWithdraw = async () => {
  const data = await getApi(API_URLS.getPendingWithdraw);
  return data;
};

export const createTransaction = async (payload) => {
  return await postApi(API_URLS.createTransaction, payload)
}

export const postSignature = async (payload) => {
  const data = await postApi(API_URLS.postSignature, payload);
  return data;
};

export const getAllWithDrawApi = async ({ searchKey, page, size, status, orderBy }) => {
  const data = await postApi(API_URLS.getAllWithdraw, {
    searchKey, page, size, status, orderBy
  })
  return data
}

export const approveWithdrawApi = async ({ withdrawId, signature }) => {
  const data = await postApi(API_URLS.approveWithdraw, {
    withdrawId,
    signature,
  });
  return data;
};

export const rejectWithdrawApi = async ({ withdrawId, note }) => {
  const data = await postApi(API_URLS.rejectWithdraw, { withdrawId, note });
  return data;
};

export const getWithdrawDetailsApi = async (withdrawId) => {
  const data = await getApi(API_URLS.getWithdrawDetails(withdrawId));
  return data;
};