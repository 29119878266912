import { Button } from 'antd'
import WalletModal from 'Component/WalletModal/WalletModal'
import React, { useState } from 'react'
import './style.scss';
const ConnectToWallet = () => {
    const [isShowModal, setIsShowModal] = useState(false)
    return (
        <div>
            <Button
                className='btn-wallet'
                onClick={() => setIsShowModal(true)}
            >
                Connect to a Wallet
            </Button>
            <WalletModal
                isShowModal={isShowModal}
                setIsShowModal={setIsShowModal} />
        </div>
    )
}

export { ConnectToWallet }
