import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chain'
// import { NetworkConnector } from './networkConnnector'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

const NETWORK_URLS = {
    [SupportedChainId.POLYGON_TESTNET]: 'https://rpc-mumbai.maticvigil.com',
    [SupportedChainId.POLYGON_MAINNET]: 'https://polygon-rpc.com',
}

export const Metamask = 'Metamask';

// export const network = new NetworkConnector({
//     urls: NETWORK_URLS,
//     defaultChainId: 1,
// })

export const injected = new InjectedConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

export const walletconnect = new WalletConnectConnector({
    supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
    rpc: NETWORK_URLS,
    qrcode: true,
})

export const connectorsByName = {
    Metamask: injected
}