import React from 'react';
import { Select } from 'antd';

const { Option } = Select;
const SelectFilter = ({ list, onChange, style, value }) => {
  return (
    <Select value={value} style={style} onChange={onChange}>
      {list &&
        list.map((item, index) => (
          <Option key={index} value={item.value}>
            {item.text}
          </Option>
        ))}
    </Select>
  );
};

export default SelectFilter;