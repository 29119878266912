import { getApi } from '../utils/apiHelper';
import { API_URLS } from './api_url';

export const getNFTStatusFilterApi = async () => {
  const data = await getApi(API_URLS.getNFTStatusFilter);
  return data;
};


export const getNFTTypeFilterApi = async () => {
  const data = await getApi(API_URLS.getNFTTypeFilter);
  return data;
};

export const getTypeFilterApi = async () => {
  const data = await getApi(API_URLS.getTypeFilter);
  return data;
};

export const getActionTypeFilterApi = async () => {
  const data = await getApi(API_URLS.getActionNFTTypeFilter);
  return data;
};