import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "api/api_url";
import axiosConfig from "api/axiosConfig";
import { setAccessToken } from "utils/localStorageService";

export const loginAuth = createAsyncThunk('auth/login', async ({ walletAddress }) => {
    const response = await axiosConfig.post(API_URLS.postAuthenticationLogin, {
        walletAddress
    });
    setAccessToken(response.data.id_token);
    return response.data;
})