import { API_URLS } from 'api/api_url'
import { getApi, postApi } from '../../utils/apiHelper'

export const getNonce = async (address) => {
    const data = await getApi(API_URLS.getNonce(address))
    return data
}

export const signin = async ({ walletAddress, signature }) => {
    const data = await postApi(
        API_URLS.verifySignature,
        { walletAddress, signature }
    )
    return data
}
