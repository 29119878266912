import ethereumLogoUrl from '../assets/images/ethereum-logo.png'

export const SupportedChainId = {
  POLYGON_MAINNET: 137,
  POLYGON_TESTNET: 80001
}

export const ALL_SUPPORTED_CHAIN_IDS = [
  SupportedChainId.POLYGON_TESTNET,
  SupportedChainId.POLYGON_MAINNET,
]

// export interface IChainInfo {
//   readonly blockWaitMsBeforeWarning?: number
//   readonly docs: string
//   readonly explorer: string
//   readonly infoLink: string
//   readonly label: string
//   readonly logoUrl?: string
//   readonly rpcUrls?: string[]
//   readonly nativeCurrency: {
//     name: string // 'ETH',
//     symbol: string // 'ETH',
//     decimals: number //18,
//   }
// }


export const CHAIN_INFO = {
  [SupportedChainId.MAINNET]: {
    docs: 'https://etherscan.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.BSC_TESTNET]: {
    docs: 'https://etherscan.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.TRUFFLE]: {
    docs: 'https://etherscan.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
  [SupportedChainId.HARDHAT]: {
    docs: 'https://etherscan.io/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  },
}
