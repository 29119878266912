import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { approveDeposit, getAllDeposit } from "redux/actions/deposit";

const initialState = {
  deposit: {
    content: [],
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      sort: { sorted: false, unsorted: false, empty: false },
      empty: false,
      sorted: false,
      unsorted: false,
      unpaged: false,
    },
    size: 0,
    sort: { sorted: false, unsorted: false, empty: false },
    totalElements: 0,
    totalPages: 0,
  },
  depositId: 0,
  depositItem: 0,
  success: false,
  error: null,
  loading: false
};
const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers:
  {
    setDepositId: (state, action) => {
      state.depositId = action.payload
    },
    setDepositItem: (state, action) => {
      state.depositItem = action.payload
    },
    resetDepositProgress: (state) => {
      state.success = false;
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeposit.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllDeposit.fulfilled, (state, action) => {
        state.loading = false
        state.deposit = action.payload
      })
      .addCase(getAllDeposit.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      });
    builder
      .addCase(approveDeposit.pending, (state) => {
        state.loading = true
      })
      .addCase(approveDeposit.fulfilled, (state, action) => {
        state.loading = false
        state.deposit = action.payload
      })
      .addCase(approveDeposit.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
});

const selectSelf = (state) => state.deposit;
const depositListSelector = createSelector(selectSelf, (state) => state.deposit)
const getDepositId = createSelector(selectSelf, (state) => state.depositId)
const getDepositItem = createSelector(selectSelf, (state) => state.depositItem)
const successDepositSelector = createSelector(selectSelf, (state) => state.success)
const errorDepositSelector = createSelector(selectSelf, (state) => state.error)
const getAllDepositSelector = createSelector(selectSelf, (state) => state.deposit)
const getAllDepositContenSelector = createSelector(getAllDepositSelector, (state) => state.content)

export const { setDepositId, setDepositItem, resetDepositProgress } = depositSlice.actions

export const depositSelector = { depositListSelector, successDepositSelector, getDepositId, getDepositItem, errorDepositSelector, getAllDepositSelector, getAllDepositContenSelector }

export default depositSlice.reducer