import { createSlice } from '@reduxjs/toolkit';
import { getActionTypeFilter, getNFTStatusFilter, getNFTTypeFilter, getTypeFilter } from '../actions/filters';
import { createSelector } from 'reselect';
const initialState = {
  actionTypeFilter: [], NFTTypeFilter: [], NFTStatusFilter: [], typeFilter: [],
  loading: false
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActionTypeFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActionTypeFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.actionTypeFilter = action.payload;
      })
      .addCase(getActionTypeFilter.rejected, (state) => {
        state.loading = false;
        state.error = state.payload
      });
    builder
      .addCase(getNFTTypeFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNFTTypeFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.NFTTypeFilter = action.payload;
      })
      .addCase(getNFTTypeFilter.rejected, (state) => {
        state.loading = false;
        state.error = state.payload
      });
    builder
      .addCase(getNFTStatusFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNFTStatusFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.NFTStatusFilter = action.payload;
      })
      .addCase(getNFTStatusFilter.rejected, (state) => {
        state.loading = false;
        state.error = state.payload
      });
    builder
      .addCase(getTypeFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTypeFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.typeFilter = action.payload;
      })
      .addCase(getTypeFilter.rejected, (state) => {
        state.loading = false;
      });
  },
});
const selectSelf = (state) => state.filters;
const typeFilterSelector = createSelector(
  selectSelf,
  (state) => state.typeFilter
);
const nFTStatusFilterSelector = createSelector(
  selectSelf,
  (state) => state.NFTStatusFilter
);
const nFTTypeFilterSelector = createSelector(
  selectSelf,
  (state) => state.NFTTypeFilter
);
const actionNFTTypeFilerSelector = createSelector(
  selectSelf,
  (state) => state.depositSorts
);
export const filterSelectors = {
  typeFilterSelector,
  actionNFTTypeFilerSelector,
  nFTTypeFilterSelector,
  nFTStatusFilterSelector,
};
export default filterSlice.reducer;
