import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from '@sentry/react'
import reportWebVitals from "./reportWebVitals";
import { Integrations } from '@sentry/tracing';
import store from "./redux/store";
import { Provider } from "react-redux";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "constants/misc";
import getLibrary from "utils/getLibrary";
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
Sentry.init({
  dsn: 'https://06951a956c7a4a6db2f776b1bc697efd@o72860.ingest.sentry.io/5842491',
  integrations: [new Integrations.BrowserTracing()],
  enabled: process.env.NODE_ENV !== 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}
ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <App />
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>
  ,
  document.getElementById("root")
);

reportWebVitals();
