import { signTypedData, createTypeData } from "./eip712";

const AlcheroTypes = {
  Alchero: [
    { name: "_creator", type: "address" },
    { name: "_id", type: "uint256" },
    { name: "_royaltyFeePercentage", type: "uint256" },
    { name: "_priceType", type: "uint256" },
    { name: "_price", type: "uint256" },
    { name: "_buyNowPrice", type: "uint256" },
    { name: "_startTime", type: "uint256" },
    { name: "_endTime", type: "uint256" },
    { name: "_status", type: "uint256" },
  ],
};

const MgemBridgeTypes = {
  AlcheMgemBridge: [
    { name: "_user", type: "address" },
    { name: "_processId", type: "uint256" },
    { name: "_token", type: "address" },
    { name: "_amount", type: "uint256" },
  ],
};

export async function signNFT(signParameters) {
  const { library, chainId, verifyingContract, account, ...message } =
    signParameters;
  const data = createTypeData(
    {
      name: "Alchero",
      chainId,
      version: "1.0.0",
      verifyingContract: signParameters.verifyingContract,
    },
    "Alchero",
    message,
    AlcheroTypes
  );
  return (await signTypedData(library, account, data)).sig;
}

export async function signDeposit(signParameters) {
  const { library, chainId, verifyingContract, account, ...message } =
    signParameters;
  const data = createTypeData(
    {
      name: "AlcheMgemBridge",
      chainId,
      version: "1.0.0",
      verifyingContract: signParameters.verifyingContract,
    },
    "AlcheMgemBridge",
    message,
    MgemBridgeTypes
  );
  return (await signTypedData(library, account, data)).sig;
}

export async function signEthers(signParameters) {
  const {
    signer,
    _creator,
    _id,
    _royaltyFeePercentage,
    _priceType,
    _price,
    _buyNowPrice,
    _startTime,
    _endTime,
    _status,
    verifyingContract,
    chainId,
  } = signParameters;
  const domain = {
    name: "Alchero",
    chainId,
    version: "1.0.0",
    verifyingContract,
  };

  const types = {
    Alchero: [
      { name: "_creator", type: "address" },
      { name: "_id", type: "uint256" },
      { name: "_royaltyFeePercentage", type: "uint256" },
      { name: "_priceType", type: "uint256" },
      { name: "_price", type: "uint256" },
      { name: "_buyNowPrice", type: "uint256" },
      { name: "_startTime", type: "uint256" },
      { name: "_endTime", type: "uint256" },
      { name: "_status", type: "uint256" },
    ],
  };

  const value = {
    _creator,
    _id,
    _royaltyFeePercentage,
    _priceType,
    _price,
    _buyNowPrice,
    _startTime,
    _endTime,
    _status,
  };

  return signer._signTypedData(domain, types, value);
}
