import { postApi } from '../utils/apiHelper';
import { API_URLS } from './api_url';

export const getAllDepositApi = async ({
  page,
  size,
  status,
  orderBy,
  searchKey,
}) => {
  const data = await postApi(API_URLS.getAllDeposit, {
    page,
    size,
    status,
    orderBy,
    searchKey,
  });
  return data;
};

export const approveDepositApi = async ({ depositId, signature }) => {
  const data = await postApi(API_URLS.approveDeposit, {
    depositId,
    signature,
  });
  return data;
};
