const DOMAIN_TYPE = [
  {
    type: 'string',
    name: 'name'
  },
  {
    type: 'string',
    name: 'version'
  },
  {
    type: 'uint256',
    name: 'chainId'
  },
  {
    type: 'address',
    name: 'verifyingContract'
  }
]

export function createTypeData(
  domainData,
  primaryType,
  message,
  types
) {
  return {
    types: Object.assign(
      {
        EIP712Domain: DOMAIN_TYPE
      },
      types
    ),
    domain: domainData,
    primaryType: primaryType,
    message: message
  }
}

export function signTypedData(web3, from, data) {
  return new Promise(async (resolve, reject) => {
    function cb(err, result) {
      if (err) {
        return reject(err)
      }
      if (result.error) {
        return reject(result.error)
      }

      const sig = result.result
      const sig0 = sig.substring(2)
      const r = '0x' + sig0.substring(0, 64)
      const s = '0x' + sig0.substring(64, 128)
      const v = parseInt(sig0.substring(128, 130), 16)

      resolve({
        data,
        sig,
        v,
        r,
        s
      })
    }
    if (web3.provider.isMetaMask) {
      web3.provider.sendAsync &&
        web3.provider.sendAsync(
          {
            method: 'eth_signTypedData_v4',
            params: [from, JSON.stringify(data)]
          },
          cb
        )
    } else {
      let send = web3.provider.sendAsync
      if (!send) send = web3.provider.send
      send &&
        send.bind(web3.provider)(
          {
            method: 'eth_signTypedData_v4',
            params: [from, data]
          },
          cb
        )
    }
  })
}
