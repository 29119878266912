import { getApi, postApi } from "../utils/apiHelper";
import { API_URLS } from "./api_url";

export const getNFTsApi = async ({
  gameItemType,
  status,
  page,
  size,
  orders,
}) => {
  const data = await postApi(API_URLS.getNfts, {
    gameItemType,
    status,
    page,
    size,
    orders,
  });
  return data;
};

export const getSignApi = async ({
  gameItemType,
  status,
  page,
  size,
  orders
}) => {
  const data = await postApi(API_URLS.postSign, {
    gameItemType,
    status,
    page,
    size,
    orders
  });
  return data;
};

export const getSignInfoApi = async (id) => {
  const data = await getApi(API_URLS.getSignInfo(id));
  return data;
};

export const postSignApi = async ({
  nftId,
  price,
  buyNowPrice,
  type,
  royalty,
  signature
}) => {
  const data = await postApi(API_URLS.postSign, {
    nftId,
    price,
    buyNowPrice,
    type,
    royalty,
    signature
  });
  return data;
};


export const removeFromSaleNotOnBlockchainApi = async ({ tokenId }) => {
  const data = await postApi(API_URLS.removeFromSaleNotOnBlockchain, { tokenId });
  return data;
}


export const getResourceHistoriesApi = async ({
  type, actionType, page, size, orders
}) => {
  const data = await postApi(API_URLS.getResourceHistories, {
    type, actionType, page, size, orders
  })
  return data
}

