import { createAsyncThunk } from "@reduxjs/toolkit";
import { approveDepositApi, getAllDepositApi } from "api/deposit";
import { instanceOfDataError } from "../../utils/apiError";

export const getAllDeposit = createAsyncThunk(
    "deposit/getAllDeposit",
    async ({ page, size, status, orderBy, searchKey }, { rejectWithValue }) => {
      const response = await getAllDepositApi({ page, size, status, orderBy, searchKey })
      if (instanceOfDataError(response)) {
        return rejectWithValue(response.error);
      }
      return response;
    },
  )

export const approveDeposit = createAsyncThunk(
  'deposit/approveDeposit',
  async ({ depositId, signature }, { rejectWithValue, dispatch }) => {
    const response = await approveDepositApi({ depositId, signature });

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);