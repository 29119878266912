import { createAsyncThunk } from '@reduxjs/toolkit'
import { getNonce, signin } from 'api/user/user'
import { instanceOfDataError } from 'utils/apiError'

export const getNonceAction = createAsyncThunk(
    'user/getNonce',
    async (address, { rejectWithValue }) => {
        const response = await getNonce(address)
        if (instanceOfDataError(response)) {
            return rejectWithValue(response.error)
        }
        return response
    }
)
export const signinAction = createAsyncThunk(
    'user/signin',
    async (data, { rejectWithValue }) => {
        const response = await signin(data)
        if (instanceOfDataError(response)) {
            return rejectWithValue(response.error)
        }
        return response
    }
)
