import { useWeb3React } from '@web3-react/core'
import { injected } from 'Component/connector'
import { NetworkContextName } from 'constants/misc'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'redux/hook'
import { setAuthorized, setNonce, setToken } from 'redux/reducer/authReducer'
import { removeAccessToken } from 'utils/localStorageService'
import { isMobile } from 'utils/userAgent'
export function useActiveWeb3React() {
    const context = useWeb3React()
    const contextNetwork = useWeb3React(NetworkContextName)
    return context.active ? context : contextNetwork
}

export function useEagerConnect() {
    const { activate, active } = useWeb3React()
    const [tried, setTried] = useState(false)

    useEffect(() => {
        if (!active) {
            injected.isAuthorized().then((isAuthorized) => {
                if (isAuthorized) {
                    activate(injected, undefined, true).catch(() => {
                        setTried(true)
                    })
                } else {
                    if (isMobile && window.ethereum) {
                        activate(injected, undefined, true).catch(() => {
                            setTried(true)
                        })
                    } else {
                        setTried(true)
                    }
                }
            })
        }
    }, [activate, active])
    // wait until we get confirmation of a connection to flip the flag
    useEffect(() => {
        if (active) {
            setTried(true)
        }
    }, [active])

    return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener() {
    const { error, activate } = useWeb3React()
    const dispatch = useAppDispatch()
    useEffect(() => {
        const { ethereum } = window

        if (ethereum && ethereum.on) {
            const handleChainChanged = () => {
                // eat errors

                activate(injected, undefined, true).catch((error) => { })
            }
            const handleAccountsChanged = (accounts) => {
                if (accounts.length > 0) {
                    activate(injected, undefined, true).catch((error) => {
                        console.error('Failed to activate after accounts changed', error)
                    })
                } else {
                    removeAccessToken()
                    dispatch(setToken(''))
                    dispatch(setNonce(''))
                    dispatch(setAuthorized(false))
                }
            }
            ethereum.on('chainChanged', handleChainChanged)
            ethereum.on('accountsChanged', handleAccountsChanged)
            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener('chainChanged', handleChainChanged)
                    ethereum.removeListener('accountsChanged', handleAccountsChanged)
                }
            }
        }
        // return undefined
    }, [activate, error, dispatch])
}
