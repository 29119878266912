import { Image } from "antd"
import React from "react"
import './Modal.scss'
export default function Option({
    link = null,
    clickable = true,
    onClick = null,
    color,
    header,
    subheader = null,
    icon,
    active = false,
    id,
}) {
    const content = (
        <div id={id} onClick={() => onClick && onClick()} className="item-login">
            <div>
                <div color={color}>
                    {/* {active ? (
                        <RightCircleOutlined />
                    ) : (
                        ''
                    )} */}
                    {header}
                </div>
                {subheader && <p>{subheader}</p>}
            </div>
            <Image src={icon} alt={'Icon'} sx={{ maxWidth: '27px' }} />
        </div>
    )
    if (link) {
        return <a target="_sblank" href={link}>{content}</a>
    }

    return content
}
