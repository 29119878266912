import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postSignature,
  getAllWithDrawApi,
  approveWithdrawApi,
  rejectWithdrawApi,
  getWithdrawDetailsApi,
} from 'api/transaction';
import { instanceOfDataError } from '../../utils/apiError';

export const postSignatureAction = createAsyncThunk(
  'transaction/postSignature',
  async (data, { rejectWithValue }) => {
    const response = await postSignature(data);

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getAllWithdraw = createAsyncThunk(
  'transaction/getAllWithdraw',
  async ({ searchKey, page, size, status, orderBy }, { rejectWithValue }) => {
    const response = await getAllWithDrawApi({
      searchKey,
      page,
      size,
      status,
      orderBy,
    });

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const approveWithdraw = createAsyncThunk(
  'transaction/approveWithdraw',
  async ({ withdrawId, signature }, { rejectWithValue }) => {
    const response = await approveWithdrawApi({ withdrawId, signature });

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const rejectWithdraw = createAsyncThunk(
  'transaction/rejectWithdraw',
  async ({ withdrawId, note }, { rejectWithValue }) => {
    const response = await rejectWithdrawApi({ withdrawId, note });
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);
export const getWithdrawDetails = createAsyncThunk(
  'transaction/getWithdrawDetails',
  async (withdrawId, { rejectWithValue }) => {
    const response = await getWithdrawDetailsApi(withdrawId);
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);