import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { loginAuth } from "redux/actions/auth";
import { getNonceAction, signinAction } from "redux/actions/user/user";
import { setAccessToken } from "utils/localStorageService";
import { createSelector } from "reselect";
const initialState = {
    token: '',
    nonce: '',
    isAuthorized: false,
    errors: {},
    loading: false,
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutSuccess(state) {
            state.token = null;
        },
        setAuthorized: (state, action) => {
            state.isAuthorized = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setNonce: (state, action) => {
            state.nonce = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAuth.fulfilled, (state, action) => {
                state.token = action.payload;
            })
            .addCase(loginAuth.rejected, () => {
            })
        builder
            .addCase(getNonceAction.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getNonceAction.fulfilled, (state, action) => {
                state.loading = false
                state.nonce = action.payload.nonce
            })
            .addCase(getNonceAction.rejected, (state, action) => {
                state.loading = false
                state.errors.getNonceAction = action.payload
                toast.error(action.payload)
            })
        builder
            .addCase(signinAction.pending, (state, action) => {
                state.loading = true
            })
            .addCase(signinAction.fulfilled, (state, action) => {
                state.loading = false
                state.isAuthorized = true
                setAccessToken(action.payload.id_token)
                state.token = action.payload.id_token
            })
            .addCase(signinAction.rejected, (state, action) => {
                state.loading = false
                state.errors.signinAction = action.payload
                toast.error(action.payload)
            })
    },
})
const selectSelf = (state) => state.auth;
const isAuthorizedSelector = createSelector(
    selectSelf,
    (state) => state.isAuthorized
)

const getNonceSelector = createSelector(
    selectSelf,
    (state) => state.nonce
)

const getTokenSelector = createSelector(
    selectSelf,
    (state) => state.token
)

export const authSelectors = {
    getNonceSelector,
    getTokenSelector,
    isAuthorizedSelector
}

export const { logoutSuccess, setAuthorized, setToken, setNonce } = authSlice.actions;
export default authSlice.reducer;