export const HERO = "HERO";
export const ALL = "ALL"

export const MGEM = "MGEM";

export const ACTIVE = "ACTIVE";
export const DEACTIVE = "DEACTIVE";

export const SOLD = "SOLD";
export const SELLING = "SELLING";
export const DATABASE = "DATABASE";

export const FIXED_PRICE = 0;
export const BID_TIME = 1;
export const BID = 2;
export const RENT = 3;
