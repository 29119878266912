
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  approveWithdraw,
  getAllWithdraw,
  rejectWithdraw,
  getWithdrawDetails,
} from 'redux/actions/transaction';

const initialState = {
  transactions: [],
  withdraw: {
    content: [],
    size: 0,
    totalElements: 0
  },
  withdrawId: 0,
  withdrawItem: {},
  withDrawDetails: {},
  success: false,
  error: null,
  loading: false,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setWithdrawId: (state, action) => {
      state.withdrawId = action.payload;
    },
    setWithdrawItem: (state, action) => {
      state.withdrawItem = action.payload;
    },
    resetWithdrawProgress: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWithdraw.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllWithdraw.fulfilled, (state, action) => {
        state.loading = false;
        state.withdraw = action.payload;
      })
      .addCase(getAllWithdraw.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(approveWithdraw.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveWithdraw.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(approveWithdraw.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
    builder
      .addCase(rejectWithdraw.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectWithdraw.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(rejectWithdraw.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
    builder
      .addCase(getWithdrawDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.withDrawDetails = action.payload;
      })
      .addCase(getWithdrawDetails.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

const selectSelf = (state) => state.transaction;
const getWithDrawId = createSelector(selectSelf, (state) => state.withdrawId);
const getWithDrawItem = createSelector(
  selectSelf,
  (state) => state.withdrawItem
);
const successWithdrawSelector = createSelector(
  selectSelf,
  (state) => state.success
);
const errorWithdrawSelector = createSelector(
  selectSelf,
  (state) => state.error
);
const getAllWithdrawSelector = createSelector(
  selectSelf,
  (state) => state.withdraw
);
const getAllWithdrawContentSelector = createSelector(
  getAllWithdrawSelector,
  (state) => state.content
);
const getWithdrawDetailsSeletor = createSelector(
  selectSelf,
  (state) => state.withDrawDetails
);
export const { setWithdrawId, setWithdrawItem, resetWithdrawProgress } =
  transactionSlice.actions;

export const transactionSelector = {
  successWithdrawSelector,
  errorWithdrawSelector,
  getWithDrawItem,
  getAllWithdrawSelector,
  getAllWithdrawContentSelector,
  getWithDrawId,
  getWithdrawDetailsSeletor,
};

export default transactionSlice.reducer;
