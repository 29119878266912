import { createSlice } from "@reduxjs/toolkit";
import { getNFTs, getSignInfo, postSign, removeFromSaleNotOnBlockchain } from "../actions/nft";
import { createSelector } from "reselect";

const initialState = {
  nfts: {
    content: [],
  },
  loading: false,
  success: false,
  message: '',
  error: null,
  signInfo: null,
  nftId: 0
};

const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    changeItemStatusFilter: (state, action) => {
      state.nfts = action.payload
    },
    resetProgress: (state) => {
      state.error = null;
      state.success = false
    },
    setNftId: (state, action) => {
      state.nftId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNFTs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNFTs.fulfilled, (state, action) => {
        state.loading = false;
        state.nfts = action.payload;
      })
      .addCase(getNFTs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getSignInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSignInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.signInfo = action.payload;
        console.log(action.payload)
      })
      .addCase(getSignInfo.rejected, (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(postSign.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        const nftItem = state.nfts.content.find((item) => item.nftId === action.payload.nftId)
        nftItem.price = action.payload.price;
        nftItem.royalty = action.payload.royalty;
        nftItem.endTime = action.payload.endTime;
        nftItem.signature = action.payload.signature;
        nftItem.startTime = action.payload.startTime;
        nftItem.saleType = action.payload.type;
        if (action.payload.saleType === 'FIX_PRICE') {
          nftItem.buyNowPrice = action.payload.price
        } else {
          nftItem.buyNowPrice = action.payload.buyNowPrice
        }
      })
      .addCase(postSign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
    builder
      .addCase(removeFromSaleNotOnBlockchain.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeFromSaleNotOnBlockchain.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.nfts.content = state.nfts.content.filter(state => state !== action.payload.tokenId)
      })
      .addCase(removeFromSaleNotOnBlockchain.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
  },
});

const selectSelf = (state) => state.nft;
const getNFTsSelector = createSelector(selectSelf, (state) => state.nfts);
const successNFTsSelector = createSelector(selectSelf, (state) => state.success);
const errorNFTsSelector = createSelector(selectSelf, (state) => state.error);
const getSignInfoSelector = createSelector(selectSelf, (state) => state.signInfo);
const getNftIdSelector = createSelector(selectSelf, (state) => state.nftId);
const getNFTsContentSelector = createSelector(
  getNFTsSelector,
  (state) => state.content
);

export const nftSelectors = {
  getSignInfoSelector,
  getNFTsSelector,
  errorNFTsSelector,
  successNFTsSelector,
  getNFTsContentSelector, getNftIdSelector
};
export const { changeItemStatusFilter, resetProgress, setNftId } = nftSlice.actions;
export default nftSlice.reducer;
