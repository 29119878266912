import { Form, Button, Input, Modal } from "antd";
import { signEthers } from "utils/sign";
import { useEffect, useState } from "react";
import axiosConfig from "api/axiosConfig";
import { API_URLS } from "api/api_url";
import { ALCHERO_ADDRESS } from "constants/addresses";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { BID, BID_TIME, FIXED_PRICE } from "constants/variables";

const SignNFT = () => {
  const { library, chainId } = useWeb3React();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [finish, setFinish] = useState(false);
  const [provider, setProvider] = useState(null);
  const [visible, setVisible] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (library) {
      setProvider(new ethers.providers.Web3Provider(library.provider));
    }

    return () => {
      setProvider(null);
    };
  }, [library]);

  const onFinish = (values) => {
    const tempWallet = new ethers.Wallet(values.privateKey);
    tempWallet.connect(provider);
    setWallet(tempWallet);
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    await autoSign(currentPage);
    setFinish(true);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setCurrentPage(0);
    setFinish(false);
    setConfirmLoading(false);
    setVisible(false);
  };

  const getNFTs = async (page) => {
    const response = await axiosConfig.post(API_URLS.getNfts, {
      gameItemType: "ALL",
      status: "ALL",
      page,
      size: 20,
      orders: [{ propertyName: "id", direction: "ASC" }],
    });

    return {
      content: response.data.content,
      total: response.data.totalPages,
    };
  };

  const postSignNFT = async (payload) => {
    const response = await axiosConfig.post(API_URLS.postSign, payload);

    return response.data;
  };

  const getPriceType = (type) => {
    switch(type) {
      case 'FIX_PRICE':
        return FIXED_PRICE;
      case 'BID':
        return BID;
      case 'BID_TIME':
        return BID_TIME;
      default:
        return FIXED_PRICE;
    }
  }

  const autoSign = async (page) => {
    const { content, total } = await getNFTs(page);
    setTotalPages(total);

    if (page < total) {
      setCurrentPage(page++);
      const promises = content.map((element) => {
        const signParameters = {
          signer: wallet,
          _creator: wallet.address,
          _id: element.nftId,
          _royaltyFeePercentage: element.royaltyFee,
          _priceType: getPriceType(element.saleType),
          _price: element.price,
          _buyNowPrice: getPriceType(element.saleType) === FIXED_PRICE ? 0 : element.buyNowPrice,
          _startTime: getPriceType(element.saleType) === BID_TIME ? element.startTime : 0,
          _endTime: getPriceType(element.saleType) === BID_TIME ? element.endTime : 0,
          _status: 1,
          verifyingContract: ALCHERO_ADDRESS,
          chainId,
        };
        return signEthers(signParameters);
      });

      const signatures = await Promise.all(promises);
      const signList = signatures.map((signature, index) => {
        const payload = {
          nftId: content[index].nftId,
          price: content[index].price,
          buyNowPrice: content[index].buyNowPrice,
          royalty: content[index].royaltyFee,
          signature,
          type: content[index].saleType,
          signAddress: wallet.address,
        };

        return postSignNFT(payload);
      });

      await Promise.all(signList);
      await autoSign(page);
    } else {
      return;
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        label="Private key"
        name="privateKey"
        rules={[{ required: true, message: "Please input your private key" }]}
      >
        <Input placeholder="Private key" />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Sign
        </Button>
      </Form.Item>
      <Modal
        title="Confirm"
        visible={visible}
        onOk={handleOk}
        okButtonProps={{ disabled: finish }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {!confirmLoading && !finish && <p>Are you sure to do this?</p>}
        {confirmLoading && !finish && (
          <p>
            Signing page {currentPage + 1}
            <br />
            Total pages: {totalPages}
          </p>
        )}
        {finish && <p>Finished!</p>}
      </Modal>
    </Form>
  );
};

export default SignNFT;
