import PrivateRoute from "Component/Nav";
import Web3ReactManager from "Component/Web3Manager";
import AdminPage from "pages/adminPage";
import SignIn from "pages/SignIn/SignIn";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Web3ReactManager>
            <div className="App">
              <Switch>
                <Route path="/login" component={SignIn} />
                <PrivateRoute component={AdminPage} ></PrivateRoute>
              </Switch>
            </div>
          </Web3ReactManager>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
