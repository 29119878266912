import { createAsyncThunk } from '@reduxjs/toolkit';
import { instanceOfDataError } from '../../utils/apiError';
import {
  getActionTypeFilterApi,
  getNFTStatusFilterApi, getNFTTypeFilterApi, getTypeFilterApi,
} from '../../api/filters';

export const getNFTStatusFilter = createAsyncThunk(
  'filters/getNFTStatusFilter',
  async (_, { rejectWithValue }) => {
    const response = await getNFTStatusFilterApi();

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getNFTTypeFilter = createAsyncThunk(
  'filters/getNFTTypeFilter',
  async (_, { rejectWithValue }) => {
    const response = await getNFTTypeFilterApi();
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);


export const getTypeFilter = createAsyncThunk(
  'filters/getTypeFilter',
  async (_, { rejectWithValue }) => {
    const response = await getTypeFilterApi();

    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

export const getActionTypeFilter = createAsyncThunk(
  'filters/getActionTypeFilter',
  async (_, { rejectWithValue }) => {
    const response = await getActionTypeFilterApi();
    if (instanceOfDataError(response)) {
      return rejectWithValue(response.error);
    }
    return response;
  }
);

