import { useWeb3React } from "@web3-react/core";
import {
  useEagerConnect,
  useInactiveListener,
} from "Component/customeHook/web3";
import { NetworkContextName } from "constants/misc";

export default function Web3ReactManager({ children }) {
  const { active } = useWeb3React();
  const {
    error: networkError,
  } = useWeb3React(NetworkContextName);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  // useEffect(() => {
  //     if (triedEager && !networkActive && !networkError && !active) {
  //         activateNetwork(network)
  //     }
  // }, [triedEager, networkActive, networkError, activateNetwork, active])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener();

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (triedEager && !active && networkError) {
    return (
      <div>
        <h3 color="#000000">
          Oops! An unknown error occurred. Please refresh the page, or visit
          from another browser or device.
        </h3>
      </div>
    );
  }

  return children;
}
