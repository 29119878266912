import {
  HomeOutlined,
  LogoutOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAuthorized, setNonce, setToken } from "redux/reducer/authReducer";
import { removeAccessToken } from "utils/localStorageService";

const Navbar = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const handleClick = (e) => {
    // setSelectedKey(e.key);
  };

  const handleDisconnect = () => {
    removeAccessToken();
    dispatch(setAuthorized(false));
    dispatch(setToken(""));
    dispatch(setNonce(""));
    history.push("/login");
  };

  const handleLinkClick = (link) => {
    history.push(link);
  };

  return (
    <div className="navbar-menu">
      <Menu mode="inline" onClick={handleClick} theme="dark">
        <Menu.Item
          icon={<HomeOutlined />}
          key="1"
          onClick={() => handleLinkClick("/")}
        >
          <span>Home Page</span>
        </Menu.Item>
        {/* <Menu.Item
          key="2"
          onClick={() => handleLinkClick("/database-management")}
          icon={<ProjectOutlined />}
        >
          <span>Database management</span>
        </Menu.Item> */}
        <Menu.Item
          key="3"
          onClick={() => handleLinkClick("/nft-management")}
          icon={<ProjectOutlined />}
        >
          <span>nft-management</span>
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => handleLinkClick("/sign-nft")}
          icon={<ProjectOutlined />}
        >
          <span>sign-nft</span>
        </Menu.Item>
        <Menu.Item
          key="11"
          onClick={handleDisconnect}
          icon={<LogoutOutlined />}
        >
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Navbar;
