import { Spin } from 'antd'
import { injected } from 'Component/connector'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import Option from './Option'

export default function PendingView({
    connector,
    error = false,
    setPendingError,
    tryActivation
}) {
    const isMetamask = window?.ethereum?.isMetaMask
    return (
        <div
            style={{
                alignItems: 'center',
                flexFlow: 'column',
                justifyContent: 'center',
                width: '100%',
                flexWrap: 'nowrap',
                '& > *': { width: '100%' }
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    flexFlow: 'row nowrap',
                    justifyContent: 'start',
                    borderRadius: '12px',
                    padding: '10px',
                    backgroundColor: '#eee',
                    marginBottom: '10px'
                }}
            >
                {error ? (
                    <div style={{ flexFlow: 'row nowrap', justifyContent: 'center' }}>
                        <div style={{ flexFlow: 'row nowrap', justifyContent: 'start', alignItems: 'center' }}>
                            <div>Error connecting</div>
                            <div
                                style={{
                                    cursor: 'pointer',
                                    padding: '6px',
                                    backgroundColor: 'green',
                                    borderRadius: '6px',
                                    marginLeft: '10px'
                                }}
                                onClick={() => {
                                    setPendingError(false)
                                    connector && tryActivation(connector)
                                }}
                            >
                                Try Again
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <Spin size={32} strokeWidth={2} style={{ stroke: 'green', marginRight: '10px' }} />
                        Initializing...
                    </>
                )}
            </div>
            {Object.keys(SUPPORTED_WALLETS).map((key) => {
                const option = SUPPORTED_WALLETS[key]
                if (option.connector === connector) {
                    if (option.connector === injected) {
                        if (isMetamask && option.name !== 'MetaMask') {
                            return null
                        }
                        if (!isMetamask && option.name === 'MetaMask') {
                            return null
                        }
                    }
                    return (
                        <Option
                            id={`connect-${key}`}
                            key={key}
                            clickable={false}
                            color={option.color}
                            header={option.name}
                            subheader={option.description}
                            icon={option.iconURL}
                        />
                    )
                }
                return null
            })}
        </div>
    )
}
