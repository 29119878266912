import { t } from "@lingui/macro";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import {
  ALL,
  BID,
  BID_TIME,
  DATABASE,
  FIXED_PRICE,
  RENT,
  SELLING,
} from "constants/variables";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNFTs,
  getSignInfo,
  postSign,
  removeFromSaleNotOnBlockchain,
} from "../../redux/actions/nft";
import {
  nftSelectors,
  resetProgress,
} from "../../redux/reducer/nftReducer";
import { signNFT } from "utils/sign";
import { useWeb3React } from "@web3-react/core";
import { ALCHERO_ADDRESS } from "constants/addresses";
import "./Nft.scss";
import Paginate from "Component/Pagination";
import { parseEther, formatEther } from "@ethersproject/units";
import { useAppSelector } from "redux/hook";
import {
  getNFTStatusFilter,
  getNFTTypeFilter,
  getTypeFilter,
} from "redux/actions/filters";
import { typeList } from "constants/constants";
import { filterSelectors } from "redux/reducer/filterReducer";
import SelectFilter from "Component/Select/Select";

const List = () => {
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { library, chainId, account } = useWeb3React();
  const [typeItem, setTypeItem] = useState(ALL);
  const [statusItem, setStatusItem] = useState(ALL);
  const [signatures, setSignatures] = useState([]);
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = useState(true);
  const [isDisableForm, setIsDisableForm] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fields, setFields] = useState([
    { name: "price", value: "" },
    { name: "priceType", value: "" },
    { name: "buyNowPrice", value: "" },
    { name: "royalty", value: "" },
    { name: "startTime", value: null },
    { name: "endTime  ", value: null },
  ]);
  const [pageNum, setPageNum] = useState(1);
  const [pagination, setPagination] = useState({
    gameItemType: typeItem,
    nftStatus: statusItem,
    page: pageNum - 1,
    size: 20,
    orders: [{ propertyName: 'id', direction: 'ASC' }],
  });
  const nftStatusFilterList = useAppSelector(filterSelectors.nFTStatusFilterSelector)
  const nftTypeFilterList = useAppSelector(filterSelectors.nFTTypeFilterSelector)
  const nftsList = useSelector((state) => state.nft.nfts.content);
  const nftsItem = useAppSelector(nftSelectors.getSignInfoSelector);
  const totalElements = useSelector((state) => state.nft.nfts.totalElements);
  let renderListNfts = nftsList.map((item) => {
    return { ...item, action: item.tokenId, key: item.tokenId };
  });
  const columns = [
    {
      title: t`Action`,
      dataIndex: "action",
      key: "nftId",
      width: "50px",
      render: (action, record) => (
        <div className="buttonBox" key={record.nftId}>
          {record.status === SELLING || record.status === DATABASE ? (
            <Button

              onClick={() => handleSign(record.nftId)}
              style={{ marginBottom: 10 }}
              type="primary"
              shape="round"
              size="small"
            >
              Sign
            </Button>
          ) : null}
          {record.status === SELLING ? (
            <Button
              onClick={() => handleRemove(record.nftId)}
              type="danger"
              shape="round"
              size="small"
            >
              Remove
            </Button>
          ) : null}
        </div>
      ),
    },
    {
      title: t`NftId`,
      dataIndex: "nftId",
      key: "nftId",
      sorter: (a, b) => a.nftId - b.nftId,
    },
    {
      title: t`GameItemId`,
      dataIndex: "gameItemId",
      key: "gameItemId",
    },
    {
      title: t`Sale Type`,
      dataIndex: "saleType",
      key: "saleType",
    },
    {
      title: t`Selling Price`,
      dataIndex: "price",
      key: "price",
      render: (text) => text && String(text),
    },
    {
      title: t`Buy Now Price`,
      dataIndex: "buyNowPrice",
      key: "buyNowPrice",
      render: (text) => text && String(text),
    },
    {
      title: t`Signature`,
      dataIndex: "signature",
      key: "signature",
      width: 300,
    },
    {
      title: t`Rarity`,
      dataIndex: "rarity",
      key: "rarity",
    },
    {
      title: t`Status`,
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleSign = (tokenId) => {
    setIsModalVisible(!isModalVisible);
    dispatch(getSignInfo(tokenId));
  };
  const handleRemove = async (tokenId) => {
    setIsRemoveModalVisible(!isRemoveModalVisible);
    dispatch(getSignInfo(tokenId));
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    setFields([
      { name: "price", value: "" },
      { name: "priceType", value: "" },
      { name: "buyNowPrice", value: "" },
      { name: "royalty", value: "" },
      { name: "startTime", value: "" },
      { name: "endTime", value: "" },
    ]);
  };
  // function handleChangeType(value) {
  //   setTypeItem(value);
  //   setPagination({
  //     ...pagination,
  //     gameItemType: value,
  //   });
  // }
  // function handleChangeStatus(value) {
  //   setStatusItem(value);
  //   setPagination({
  //     ...pagination,
  //     nftStatus: value,
  //   });
  // }

  useEffect(() => {
    dispatch(getNFTStatusFilter());
    dispatch(getNFTTypeFilter());
    dispatch(getTypeFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getNFTs({
        gameItemType: typeItem,
        status: statusItem,
        page: pageNum - 1,
        size: 20,
        orders: [{ propertyName: 'id', direction: 'ASC' }]
      })
    );
  }, [dispatch, statusItem, pageNum, typeItem]);

  const handleSignItem = (tokenId) => {
    form.validateFields().then(async (values) => {
      console.log("form:", values);
      const signParams = {
        account,
        library,
        chainId,
        verifyingContract: ALCHERO_ADDRESS,
        _creator: account,
        _id: tokenId,
        _royaltyFeePercentage: values?.royalty,
        _price: parseEther(values.price).toString(),
        _buyNowPrice:
          values.priceType === 0 ? 0 : parseEther(values.buyNowPrice).toString(),
        _priceType: values.priceType,
        _startTime: values?.startTime
          ? new Date(values?.startTime / 1000).getTime()
          : 0,
        _endTime: values?.endTime
          ? new Date(values?.endTime / 1000).getTime()
          : 0,
        _status: 1,
      };
      const sig = await signNFT(signParams);
      if (sig) {
        setSignatures([...signatures, sig]);
        const nftsItemForm = {
          ...values,
          price: parseEther(values.price).toString(),
          buyNowPrice: parseEther(values.buyNowPrice).toString(),
          startTime: values?.startTime
            ? new Date(values?.startTime / 1000).getTime()
            : 0,
          endTime: values?.endTime
            ? new Date(values?.endTime / 1000).getTime()
            : 0,
          signature: sig,
          type: typeList[values.priceType],
          nftId: tokenId,
        };
        dispatch(postSign(nftsItemForm));
      }
    });
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    if (nftsItem) {
      let startDate = nftsItem?.startTime
        ? moment(1000 * nftsItem?.startTime)
        : "";
      let endDate = nftsItem?.endTime ? moment(1000 * nftsItem?.endTime) : "";
      setFields([
        { name: "priceType", value: nftsItem?.type },
        { name: "price", value: formatEther(String(nftsItem?.price)) },
        {
          name: "buyNowPrice",
          value: formatEther(String(nftsItem?.buyNowPrice)),
        },
        { name: "royalty", value: nftsItem?.royalty },
        { name: "startTime", value: startDate },
        { name: "endTime", value: endDate },
      ]);
    }
  }, [nftsItem]);

  useEffect(() => {
    dispatch(resetProgress());
  }, [dispatch]);

  const onPageChange = (newPage, pageSize) => {
    setCurrentPage(newPage);
    setPageNum(newPage);
    setPagination({
      ...pagination,
      page: newPage - 1,
      size: pageSize,
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().add(-1, "day");
  };

  const handleChange = (value) => {
    if (value === BID) {
      setIsDisable(true);
      setIsDisableForm(false);
    } else if (value === FIXED_PRICE) {
      setIsDisable(true);
      setIsDisableForm(true);
    } else {
      setIsDisable(false);
      setIsDisableForm(false);
    }
  };

  const removeSubmit = (tokenId) => {
    setIsRemoveModalVisible(!isRemoveModalVisible);
    dispatch(
      removeFromSaleNotOnBlockchain({
        nftId: tokenId,
      })
    );
  };

  const removeCancel = () => {
    setIsRemoveModalVisible(!isRemoveModalVisible);
  };

  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col sm={12} md={8} lg={6} className="filter">
          <Col span={12} md={8}>
            NFT Type
          </Col>
          <Col span={12} md={16}>
            <SelectFilter list={nftStatusFilterList} value={statusItem} onChange={setStatusItem} />
          </Col>
        </Col>
        <Col sm={12} md={10} lg={6} className="filter">
          <Col sm={12} md={8}>
            NFT Status
          </Col>
          <Col sm={12} md={16}>
            <SelectFilter list={nftTypeFilterList} value={typeItem} onChange={setTypeItem} />
          </Col>
        </Col>
      </Row>
      <Table
        bordered
        scroll={{ x: 'auto' }}
        dataSource={renderListNfts}
        pagination={false}
        columns={columns}
        rowKey="nftId"
      />
      <Paginate
        totalElements={totalElements}
        onPageChange={onPageChange}
        pageSize={pagination.size}
        currentPage={currentPage}
      />
      <Modal
        title={`Sign for "${typeItem} Name" - TokenId: ${nftsItem?.nftId}`}
        visible={isModalVisible}
        onOk={() => handleSignItem(nftsItem?.nftId)}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Sign"
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          fields={fields}
        >
          <Form.Item name="priceType" label="Price Type">
            <Select onChange={handleChange}>
              <Option value={BID_TIME}>Bid Time</Option>
              <Option value={FIXED_PRICE}>Fixed Price</Option>
              <Option value={BID}>Bid</Option>
              <Option value={RENT}>Rent</Option>
            </Select>
          </Form.Item>
          <Form.Item name="price" label="Price">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="buyNowPrice"
            label="Buy Now Price"
            help="Buy now Price should be greater than Price "
            dependencies={["price", "priceType"]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, buyNowPrice) {
                  console.log(getFieldValue("priceType"));
                  if (
                    getFieldValue("priceType") === 'FIX_PRICE' ||
                    getFieldValue("priceType") <= buyNowPrice
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Buy now Price should be greater than Price ")
                    );
                  }
                },
              }),
            ]}
          >
            <Input disabled={isDisableForm} />
          </Form.Item>
          <Form.Item name="royalty" label="Royalty">
            <Input />
          </Form.Item>
          <Form.Item name="startTime" label="Start time">
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={disabledDate}
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item name="endTime" label="End time">
            <DatePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={disabledDate}
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              disabled={isDisable}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* remove Modal */}
      <Modal
        title={`Remove - TokenId: ${nftsItem?.nftId}`}
        visible={isRemoveModalVisible}
        onOk={() => removeSubmit(nftsItem?.nftId)}
        onCancel={removeCancel}
        cancelText="Cancel"
        okText="Submit"
      >
        Are you sure?
      </Modal>
    </div>
  );
};

export default List;
