import { Pagination } from 'antd';
import React from 'react';
import './style.scss';
function Paginate({ totalElements, onPageChange, pageSize, currentPage }) {
    return (
        <Pagination
            className='pagination'
            total={totalElements}
            showSizeChanger
            showQuickJumper
            responsive={true}
            current={currentPage}
            pageSize={pageSize}
            onChange={onPageChange}
            showTotal={total => `Total ${total} items`}
        />
    );
}

export default Paginate;