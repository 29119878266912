import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { getAccessToken } from "utils/localStorageService";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const accessToken = getAccessToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

export default PrivateRoute;
