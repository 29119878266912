export const API_URLS = {
  postAuthenticationLogin: '/user/metamask-authenticate',
  verifySignature: '/user/metamask-verify-signature',

  getSignInfo: (tokenId) => {
    return `/gmt/get-sign-info/${tokenId}`;
  },

  getActionTypeFilter: `/gmt/get-action-type-filter`,
  getTypeFilter: `/gmt/get-type-filter`,
  getResourceHistories: `/gmt/get-resource-histories`,
  getNFTTypeFilter: `/gmt/get-nft-type-filter`,
  getNFTStatusFilter: `/gmt/get-nft-status-filter`,
  getNfts: '/gmt/get-nfts',
  postSign: '/gmt/sign',
  removeFromSaleNotOnBlockchain:
    '/market-place-write/remove-from-sale-not-on-blockchain',
  getNonce: (address) => {
    return `/user/address/${address}`;
  },

};