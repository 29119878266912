import { CloseCircleOutlined } from "@ant-design/icons"
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { injected } from "Component/connector"
import usePrevious from "Component/customeHook/usePrevious"
import { Modal } from "Component/Modal/Modal"
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useAppDispatch } from "redux/hook"
import { authSelectors, setNonce } from "redux/reducer/authReducer"
import { isMobile } from "utils/userAgent"
import MetamaskIcon from '../../assets/images/metamask.png'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import { getNonceAction, signinAction } from '../../redux/actions/user/user'
import Option from './Option'
import PendingView from './PendingView'
import './Modal.scss'
const WALLET_VIEWS = {
    OPTIONS: 'options',
    PENDING: 'pending'
}

export default function WalletModal({ isShowModal, setIsShowModal }) {
    const dispatch = useAppDispatch()
    const nonce = useSelector(authSelectors.getNonceSelector)

    const isAuthorized = useSelector(authSelectors.isAuthorizedSelector)
    // important that these are destructed from the account-specific web3-react context
    const { active, account, library, connector, activate, error } = useWeb3React();
    const [walletView, setWalletView] = useState(WALLET_VIEWS.OPTIONS)

    const [pendingWallet, setPendingWallet] = useState()

    const [pendingError, setPendingError] = useState()

    const previousAccount = usePrevious(account)

    const handleAuthenticate = useCallback(
        (data) => dispatch(signinAction(data)),
        [dispatch]
    )
    const handleSignMessage = useCallback(async () => {
        if (!library || !account || !nonce) {
            return
        }
        try {
            const signer = library.getSigner()
            const signature = await signer.signMessage(nonce)
            handleAuthenticate({ walletAddress: account, signature })
        } catch (err) {
            // throw new Error('You need to sign the message to be able to log in.')
            toast.error('You need to sign the message to be able to log in.')
            setIsShowModal(false)
            dispatch(setNonce(''))
        }
    }, [library, account, nonce, handleAuthenticate, dispatch, setIsShowModal])
    const handleSignin = useCallback(
        (address) => dispatch(getNonceAction(address)),
        [dispatch]
    )
    // close on connection, when logged out before
    useEffect(() => {
        if (account && !previousAccount && isShowModal) {
            handleSignin(account)
        }
    }, [account, previousAccount, isShowModal, handleSignin])
    useEffect(() => {
        if (nonce) {
            handleSignMessage()
        }
    }, [nonce, handleSignMessage])

    useEffect(() => {
        if (isShowModal) {
            setPendingError(false)
            setWalletView(WALLET_VIEWS.OPTIONS)
        }
    }, [isShowModal])
    // remove when disconnect manunally
    // close modal when a connection is successful
    const activePrevious = usePrevious(active)
    const connectorPrevious = usePrevious(connector)
    useEffect(() => {
        if (
            isShowModal &&
            // !((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))
            !((active && !activePrevious) || (connector && !error))
        ) {
            setWalletView(WALLET_VIEWS.OPTIONS)
        }
    }, [setWalletView, active, error, connector, isShowModal, activePrevious, connectorPrevious])

    const tryActivation = async (connector) => {
        setPendingWallet(connector) // set wallet for pending view
        setWalletView(WALLET_VIEWS.PENDING)

        // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
        if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
            connector.walletConnectProvider = undefined
        }

        connector &&
            activate(connector, undefined, true).catch((error) => {
                if (error instanceof UnsupportedChainIdError) {
                    activate(connector) // a little janky...can't use setError because the connector isn't set
                } else {
                    setPendingError(true)
                }
            })
    }

    // get wallets user can switch too, depending on device/browser
    function getOptions() {
        const isMetamask = window.ethereum && window.ethereum.isMetaMask
        return Object.keys(SUPPORTED_WALLETS).map((key) => {
            const option = SUPPORTED_WALLETS[key]

            // check for mobile options
            if (isMobile) {
                if (!window.web3 && !window.ethereum && option.mobile) {
                    return (
                        <Option
                            onClick={() => {
                                option.connector !== connector &&
                                    isAuthorized &&
                                    !option.href &&
                                    tryActivation(option.connector)
                            }}
                            id={`connect-${key}`}
                            key={key}
                            active={option.connector && option.connector === connector && isAuthorized}
                            color={option.color}
                            link={option.href}
                            header={option.name}
                            subheader={null}
                            icon={option.iconURL}
                        />
                    )
                }
                return null
            }

            // overwrite injected when needed
            if (option.connector === injected) {
                // don't show injected if there's no injected provider
                if (!(window.web3 || window.ethereum)) {
                    if (option.name === 'MetaMask') {
                        return (
                            <Option
                                id={`connect-${key}`}
                                key={key}
                                color={'#E8831D'}
                                header={'Install Metamask'}
                                subheader={null}
                                link={'https://metamask.io/'}
                                icon={MetamaskIcon}
                            />
                        )
                    } else {
                        return null //dont want to return install twice
                    }
                }
                // don't return metamask if injected provider isn't metamask
                else if (option.name === 'MetaMask' && !isMetamask) {
                    return null
                }
                // likewise for generic
                else if (option.name === 'Injected' && isMetamask) {
                    return null
                }
            }

            // return rest of options
            return (
                !isMobile &&
                !option.mobileOnly && (
                    <Option
                        id={`connect-${key}`}
                        onClick={() => {
                            option.connector === connector && isAuthorized
                                ? setWalletView(WALLET_VIEWS.OPTIONS)
                                : !option.href && tryActivation(option.connector)
                            if (account && library) {
                                dispatch(getNonceAction(account))
                            }
                        }}
                        key={key}
                        active={option.connector === connector && isAuthorized}
                        color={option.color}
                        link={option.href}
                        header={option.name}
                        subheader={null} //use option.descriptio to bring back multi-line
                        icon={option.iconURL}
                    />
                )
            )
        })
    }

    function getModalContent() {
        if (error) {
            return (
                <div
                    className="modal-box"
                >
                    <div className="modal-box__content">
                        <h3>
                            {error ? 'Wrong Network' : 'Error connecting'}
                        </h3>
                        <CloseCircleOutlined
                            name="close"
                            fill="#7e8289"
                            height={12}
                            width={12}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setIsShowModal(false)} />
                    </div>
                    <div>
                        {error ? (
                            <h5>Please connect to the appropriate Ethereum network</h5>
                        ) : (
                            <h5>Error connecting. Try refreshing the page.</h5>
                        )}
                    </div>
                </div>
            )
        }
        return (
            <div
                className="modal-box"
            >
                <div className="modal-box__content">
                    <h3>
                        {walletView === WALLET_VIEWS.PENDING ? 'Back' : 'Select a Wallet'}
                    </h3>
                    <CloseCircleOutlined onClick={() => setIsShowModal(false)} />
                </div>
                <div style={{ flexDirection: 'column' }}>
                    {walletView === WALLET_VIEWS.PENDING ? (
                        <>
                            <div style={{ marginBottom: '16px' }}>
                                <div style={{ padding: '10px', backgroundColor: '#eee', borderRadius: '6px' }}>
                                    By connecting a wallet, you agree to Mavia’s{' '}
                                    <a href="/">Terms of Service</a> and acknowledge that you have read and
                                    understand the <a href="/">Mavia protocol disclaimer</a>.
                                </div>
                            </div>
                            <PendingView
                                connector={pendingWallet}
                                error={pendingError}
                                setPendingError={setPendingError}
                                tryActivation={tryActivation}
                            />
                        </>
                    ) : (
                        getOptions()
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            {isShowModal && (
                <Modal
                    showModal={isShowModal}
                    setShowModal={setIsShowModal}
                    styles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
                >
                    {getModalContent()}
                </Modal>
            )}

        </div>
    )
}
